import { fixReleaseData } from '@app/providers/release-control/release-control.model';
import release from '../release.json';

export interface Environment {
  name: string;
  production: boolean;
  sentryDSN: string;
  build?: string;
  apiEndpoint?: string;
  configValidatorApi?: string;
  configManagerApi?: string;
  configManagerFastApi?: string;
  configManagerV2Api?: string;
  auth?: any;
}

const defaultEnvironment: Environment = {
  name: 'default',
  production: false,
  sentryDSN: null,
  build: fixReleaseData(release).build,
  apiEndpoint: getApiEndpoint(),
  configValidatorApi: 'https://pro-config-validator-api.bs.laicosp.net',
  configManagerApi: 'https://configmanager.pro.backend.bs.laicosp.net',
  configManagerFastApi: 'https://configmanager-node.pro.backend.bs.plaicos.net',
  configManagerV2Api: 'https://configmanagergo.pro.backend.bs.plaicos.net',
};

export function extendDefaultWith(env: Environment): Environment {
  return Object.assign<any, Environment, Environment>(
    {},
    defaultEnvironment,
    env,
  );
}

export function getApiEndpoint(currentUrl = window.location.href): string {
  const url = new URL(currentUrl);

  url.pathname = '/api/v1/console';
  url.search = '';
  url.hash = '';

  // fix for safari and ios mobile
  url.href = url.href.replace('?', '');

  if (/localhost/.test(url.hostname)) {
    url.port = '8888';
  } else {
    url.hostname = 'jarvis-manager.bs.laicosp.net';
  }

  return url.toString();
}
