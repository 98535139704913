import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared';
import { EditorService } from './editor/editor.service';
import { KendoSetupService } from './shared/kendo/kendo-setup.service';
import { ConfigManagerService } from './shared/api.service';
import { ConfigManagerSyncService } from './shared/sync.service';
import { ConfigManagerV2Service } from './shared/api-v2.service';

@NgModule({
  imports: [SharedModule],
  providers: [
    EditorService,
    KendoSetupService,
    ConfigManagerService,
    ConfigManagerV2Service,
    ConfigManagerSyncService,
  ],
})
export class ConfigManagerModule {}
