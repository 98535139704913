import { PreloadAllModules } from '@angular/router';

import { extendDefaultWith } from './base';
import { AuthParams } from '@app/providers/auth/auth.model';
import { cognitoAuthProviders } from '@app/providers/auth/cognito/cognito.provider';
import { GithubParams } from '@app/providers/github/github.model';

export const environment = extendDefaultWith({
  name: 'production',
  production: true,
  sentryDSN: 'https://9e103fb49e7248a2bb9ddfd1cf347ffe@sentry.io/1861837',
});

export const routerConfig = {
  preloadingStrategy: PreloadAllModules,
};

export const swConfig = {
  enabled: true,
};

export const auth: AuthParams = {
  providers: cognitoAuthProviders,
  config: {
    identityPoolId: 'us-east-1:e2979e7b-bde8-4135-90d2-c5d0e35d851c',
    region: 'us-east-1',
    userPoolId: 'us-east-1_OxVxWuEUx',
    userPoolWebClientId: '2ma4qsm4is5iv6moehjelslk5r',
    appClientDomain: 'pro-toolbox-auth.auth.us-east-1.amazoncognito.com',
    identityProvider: 'OktaOpenIdT2',
    redirectSignIn: 'https://jarvis-console.bs.laicosp.net/callback/cognito',
    redirectSignOut: 'https://jarvis-console.bs.laicosp.net/logout',
    forbiddenPaths: ['/callback/github'],
  },
  interceptor: {
    whitelisting: [
      'jarvis-manager.bs.laicosp.net',
      'configmanager.pro.backend.bs.laicosp.net',
      'gateway.bs.laicosp.net',
      'mdi.bs.laicosp.net',
      'pro-config-validator-api.bs.laicosp.net',
      '%ANY_WORD%-toolboxproxy.socialpointgames.com',
    ],
  },
};

export const github: GithubParams = {
  config: {
    forbiddenPaths: ['/callback/github', '/callback/cognito'],
  },
  interceptor: {
    whitelisting: [
      'configmanager.pro.backend.bs.laicosp.net',
      'configmanager-node.pro.backend.bs.plaicos.net',
      'configmanagergo.pro.backend.bs.plaicos.net',
    ],
  },
};
